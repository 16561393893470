import classnames from "classnames";
import React from "react";
import styles from "./index.module.css";

interface ContainerProps {
  h100?: boolean;
  size?: "fluid" | "sm" | "md" | "lg";
  children: React.ReactNode;
}

const Container = ({ h100 = false, size = "md", children }: ContainerProps) => (
  <div
    className={classnames({
      [styles.h100]: h100,
      [styles.fluid]: size === "fluid",
      [styles.sm]: size === "sm",
      [styles.md]: size === "md",
      [styles.lg]: size === "lg",
    })}
  >
    {children}
  </div>
);

export default Container;
