import { Link } from "react-router-dom";
import Container from "../container";
import styles from "./index.module.css";

const Navbar = () => {
  return (
    <div className={styles.navbar}>
      <Container size="sm">
        <div className={styles.navList}>
          <Link to="/">
            <div className={styles.navBrand}>
              <h3>dignumgray.com</h3>
            </div>
          </Link>

          <div className={styles.navRightButtons}>
            <Link to="/">
              <div className={styles.navItem}>Home</div>
            </Link>
            <Link to="/events">
              <div className={styles.navItem}>Events</div>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
