import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EventType } from "../types";
import { getDistance } from "../utils";
import type { RootState } from "./store";

type UserState = {
  isLoggedIn: boolean | null;
  events: EventType[];
};

const initialState: UserState = {
  isLoggedIn: null,
  events: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setEvents: (state, action: PayloadAction<EventType[]>) => {
      state.events = action.payload;
    },
  },
});

export const { setIsLoggedIn, setEvents } = userSlice.actions;

export const selectIsLoggedIn = (state: RootState) => state.user.isLoggedIn;

export const selectEvents = (state: RootState) => state.user.events;

export const selectEvent = (state: RootState, id: string) => {
  const events = state.user.events.filter((event) => event.id === id);
  return events.length < 1 ? null : events[0];
};

export const selectNextEvent = (state: RootState) => {
  const events = state.user.events;
  if (events.length === 0) {
    return null;
  }
  const event = state.user.events.reduce((closest, next) => {
    const closestDistance = getDistance(closest.startDate);
    if (closestDistance < 0) {
      return next;
    }
    const nextDistance = getDistance(next.startDate);
    if (nextDistance < 0) {
      return closest;
    }
    return closestDistance < nextDistance ? closest : next;
  });

  return getDistance(event.startDate) < 0 ? null : event;
};

export default userSlice.reducer;
