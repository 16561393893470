import spinner from "./spinner.svg";
import styles from "./index.module.css";

const Loading = () => {
  return (
    <div className={styles.loading}>
      <div className={styles.centered}>
        <img src={spinner} alt="Loading spinner" />
      </div>
    </div>
  );
};

export default Loading;
