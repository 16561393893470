import { useParams } from "react-router-dom";
import Container from "../../components/container";
import Countdown from "../../components/countdown";
import Loading from "../../components/loading";
import { useAppSelector } from "../../redux/hooks";
import { selectEvent } from "../../redux/userSlice";
import { commaSeparatedList } from "../../utils";
import styles from "./index.module.css";

const Event = () => {
  const { id } = useParams() as any;

  const eventData = useAppSelector((state) => selectEvent(state, id));

  return (
    <Container h100 size="sm">
      {eventData === null ? (
        <Loading />
      ) : (
        (() => {
          const { id, title, emoji, startDate, endDate, locations } = eventData;
          return (
            <>
              <h1 className={styles.title}>{`${emoji} ${title}`}</h1>
              <h2>
                <Countdown tminus date={startDate} />
              </h2>
              <p>
                {startDate.toDateString()}&ndash;{endDate.toDateString()}
              </p>
              <p>
                Locations:{" "}
                {commaSeparatedList(locations.map((location) => location.name))}
              </p>
              <p>ID: {id}</p>
            </>
          );
        })()
      )}
    </Container>
  );
};

export default Event;
