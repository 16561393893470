import { Link } from "react-router-dom";
import { EventType } from "../../types";
import { commaSeparatedList } from "../../utils";
import Countdown from "../countdown";
import styles from "./index.module.css";

const MeetingCard = ({
  id,
  title,
  emoji,
  startDate,
  endDate,
  locations,
}: EventType) => (
  <Link className={styles.link} to={`/events/${id}`}>
    <div className={styles.card}>
      <h3>{`${emoji} ${title}`}</h3>
      <Countdown date={startDate} />
      <p>
        {startDate.toDateString()}&ndash;{endDate.toDateString()}
      </p>
      <p>{`Location: ${commaSeparatedList(
        locations.map((location) => location.name)
      )}`}</p>
    </div>
  </Link>
);

export default MeetingCard;
