import Countdown from "../../components/countdown";
import Loading from "../../components/loading";
import { useAppSelector } from "../../redux/hooks";
import { selectNextEvent } from "../../redux/userSlice";
import styles from "./index.module.css";

const Dashboard = () => {
  const nextEvent = useAppSelector(selectNextEvent);

  return (
    <div className={styles.dashboard}>
      {(() => {
        switch (nextEvent) {
          case null:
            return <Loading />;
          case undefined:
            return <p>There are no events in your calendar</p>;
          default:
            return (
              <h1>
                <Countdown date={nextEvent.startDate} />
              </h1>
            );
        }
      })()}
    </div>
  );
};

export default Dashboard;
