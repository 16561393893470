import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./screens/dashboard";
import Calendar from "./screens/calendar";
import NotFound from "./screens/notFound";
import Footer from "./components/footer";
import LoginScreen from "./screens/login";
import myFirebase from "./firebase";
import { useEffect } from "react";
import { useAppDispatch } from "./redux/hooks";
import { setEvents, setIsLoggedIn } from "./redux/userSlice";
import PrivateRoute from "./components/privateRoute";
import Navbar from "./components/navbar";
import Event from "./screens/event";
import { EventType } from "./types";

const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    myFirebase.auth().onAuthStateChanged((user) => {
      dispatch(setIsLoggedIn(!!user));
      if (!user) {
        dispatch(setEvents([]));
        return;
      }
      const db = myFirebase.firestore();
      db.collection("events")
        .where(`roles.${user.uid}`, "array-contains", "reader")
        .get()
        .then((querySnapshot) => {
          let events: EventType[] = [];
          querySnapshot.forEach((doc) => {
            events.push({
              id: doc.id,
              title: doc.get("title"),
              emoji: doc.get("emoji"),
              startDate: doc.get("startDate").toDate(),
              endDate: doc.get("endDate").toDate(),
              locations: doc.get("locations"),
            });
          });
          events.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());
          dispatch(setEvents(events));
        });
    });
  }, [dispatch]);

  return (
    <div className="App">
      <Router>
        <div className="navbar">
          <Navbar />
        </div>
        <div className="content">
          <Switch>
            <PrivateRoute exact path="/">
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute path="/events/:id">
              <Event />
            </PrivateRoute>
            <PrivateRoute path="/events">
              <Calendar />
            </PrivateRoute>
            <Route path="/login">
              <LoginScreen />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </Router>
      <Footer />
    </div>
  );
};

export default App;
