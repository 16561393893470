import React, { FC } from "react";
import { Route } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import { selectIsLoggedIn } from "../../redux/userSlice";
import LoginScreen from "../../screens/login";
import Loading from "../loading";

const PrivateRoute: FC<React.ComponentProps<typeof Route>> = (props) => {
  const userIsLoggedIn = useAppSelector(selectIsLoggedIn);

  const content = (() => {
    switch (userIsLoggedIn) {
      case null:
        return <Loading />;
      case false:
        return <LoginScreen />;
      case true:
        return props.children;
    }
  })();

  return <Route {...props}>{content}</Route>;
};

export default PrivateRoute;
