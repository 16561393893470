import styles from "./index.module.css";

const Footer = () => {
  return (
    <div className={styles.footer}>
      <p className={styles.text}>&copy; 2021 Dignum-Gray</p>
    </div>
  );
};

export default Footer;
