import myFirebase from "firebase/app";

const config = {
  apiKey: "AIzaSyAYa6TTAtQVrrOdqM0MYOKhQi9OILBiOyc",
  authDomain: "dignum-gray.firebaseapp.com",
  projectId: "dignum-gray",
  storageBucket: "dignum-gray.appspot.com",
  messagingSenderId: "716892579840",
  appId: "1:716892579840:web:8b20d5c0c7aa037fad10e5",
  measurementId: "G-DZP40M56P5",
};

myFirebase.initializeApp(config);

export default myFirebase;
