import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import myFirebase from "../../firebase";
import "firebase/auth";
import styles from "./index.module.css";

const uiConfig = {
  signInFlow: "redirect",
  signInSuccessUrl: "/calendar",
  signInOptions: [myFirebase.auth.EmailAuthProvider.PROVIDER_ID],
};

function LoginScreen() {
  return (
    <div className={styles.column}>
      <StyledFirebaseAuth
        uiConfig={uiConfig}
        firebaseAuth={myFirebase.auth()}
      />
    </div>
  );
}

export default LoginScreen;
