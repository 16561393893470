import { useEffect, useState } from "react";
import { getDistance } from "../../utils";

const getCountdownText = (date: Date, tminus: boolean) => {
  const distance = getDistance(date);

  const days = Math.abs(Math.floor(distance / (1000 * 60 * 60 * 24)));
  const hours = Math.abs(
    Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  );
  const minutes = Math.abs(
    Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  );
  const seconds = Math.abs(Math.floor((distance % (1000 * 60)) / 1000));

  if (tminus) {
    const sign = distance >= 0 ? "-" : "+";
    return `T ${sign} ${days}d ${hours}h ${minutes}m ${seconds}s`;
  }
  if (distance < 0) {
    return "EXPIRED";
  }
  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};

type CountdownProps = {
  date: Date;
  tminus?: boolean;
};

const Countdown = ({ date, tminus = false }: CountdownProps) => {
  const initialCountdownText = getCountdownText(date, tminus);
  const [countdownText, setCountdownText] = useState(initialCountdownText);

  useEffect(() => {
    const x = setInterval(() => {
      setCountdownText(getCountdownText(date, tminus));
    }, 1000);
    return () => {
      clearInterval(x);
    };
  }, [date, tminus]);

  return <code>{countdownText}</code>;
};

export default Countdown;
