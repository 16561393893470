import MeetingCard from "../../components/meetingCard";
import styles from "./index.module.css";
import { EventType } from "../../types";
import "firebase/firestore";
import { useAppSelector } from "../../redux/hooks";
import { selectEvents } from "../../redux/userSlice";
import { getDistance } from "../../utils";

const Calendar = () => {
  const events = useAppSelector(selectEvents);

  const upcomingEvents = events.filter(
    (event) => getDistance(event.startDate) >= 0
  );

  return (
    <div className={styles.calendar}>
      <h1>Calendar</h1>
      <div className={styles.cardList}>
        {upcomingEvents.map((event: EventType) => (
          <MeetingCard key={event.id} {...event} />
        ))}
      </div>
    </div>
  );
};

export default Calendar;
