import { useLocation } from "react-router-dom";
import styles from "./index.module.css";

const NotFound = () => {
  let location = useLocation();

  return (
    <div className={styles.column}>
      <h3>
        No page can be found at <code>{location.pathname}</code>
      </h3>
      <a href="/">
        <button>
          <h3 className={styles.buttonText}>Go to the homepage</h3>
        </button>
      </a>
    </div>
  );
};

export default NotFound;
